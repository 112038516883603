import type { ImageProps } from "../util";
import { ContentfulImage } from "./contentful-image";

export const ContentfulPicture = ({
  image,
  className = "",
  isBanner = false,
  numberOfColumns = [1, 1, 1],
  icon = false,
}: {
  image: ImageProps;
  className?: string;
  isBanner?: boolean;
  numberOfColumns?: [number, number, number];
  icon?: boolean;
}) => {
  return (
    <ContentfulImage
      lazy={isBanner ? false : true}
      imageUrl={image?.fields.image?.fields.file?.url ?? ""}
      mobileImageUrl={image?.fields?.mobileImage?.fields.file?.url ?? ""}
      alt={image.fields.alt || ""}
      className={className}
      numberOfColumns={numberOfColumns}
      isBanner={isBanner}
      icon={icon}
    />
  );
};
