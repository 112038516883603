import { Link } from "@remix-run/react";

import { useContentfulInspectorMode } from "@contentful/live-preview/react";

import { useURL } from "~/contexts";

import type { IconContent } from "../types";

type Props = {
  content: IconContent;
  height: number;
};

export const Icon = ({ content, height }: Props) => {
  const link = content.fields.link || "";
  const imageAltText = content.fields.alt;
  const fileUrl = content.fields.image?.fields.file?.url;
  const imageUrl = `https:${fileUrl}?h=${height}`;
  const inspectorProps = useContentfulInspectorMode();
  /* For more Contentful image setups, like the height query, please check:
     https://www.contentful.com/developers/docs/references/images-api/
     and
     https://www.contentful.com/developers/docs/concepts/images/
  */

  const url = useURL();
  const isExternal = link && link.includes("https://");

  const imgElement = (
    <img
      src={imageUrl}
      alt={imageAltText}
      loading="lazy"
      style={{ height: `${height}px`, width: "auto" }}
    />
  );

  if (!link) {
    return imgElement;
  }

  if (isExternal) {
    return (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        {...inspectorProps({ entryId: content.sys.id, fieldId: "name" })}
      >
        {imgElement}
      </a>
    );
  }

  return (
    <Link
      to={url(link)}
      {...inspectorProps({ entryId: content.sys.id, fieldId: "name" })}
    >
      {imgElement}
    </Link>
  );
};
