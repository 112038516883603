import { useContentfulInspectorMode } from "@contentful/live-preview/react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import { options, robotoOptions } from "../util";

export const RichTextRender = ({
  content,
  entryId,
  color,
  textAlignment = "left",
  textClassname = "",
}: {
  content: Parameters<typeof documentToReactComponents>[0];
  entryId: string;
  color?: string;
  textAlignment?: CanvasTextAlign;
  textClassname?: string;
}) => {
  const inspectorProps = useContentfulInspectorMode();
  return (
    <div
      className={textClassname}
      style={{ color: color, textAlign: textAlignment }}
      {...inspectorProps({ entryId: entryId, fieldId: "name" })}
    >
      {documentToReactComponents(
        content,
        textClassname.includes("font-roboto") ? robotoOptions : options,
      )}
    </div>
  );
};
