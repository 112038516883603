import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from "contentful";

export interface TypeContentTileTitleFields {
  name: EntryFieldTypes.Symbol;
  title?: EntryFieldTypes.Symbol;
  description?: EntryFieldTypes.Symbol;
  textAlignment?: EntryFieldTypes.Symbol<"center" | "left" | "right">;
}

export type TypeContentTileTitleSkeleton = EntrySkeletonType<
  TypeContentTileTitleFields,
  "contentTileTitle"
>;
export type TypeContentTileTitle<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode,
> = Entry<TypeContentTileTitleSkeleton, Modifiers, Locales>;

export function isTypeContentTileTitle<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode,
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypeContentTileTitle<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "contentTileTitle";
}
