import { useContentfulInspectorMode } from "@contentful/live-preview/react";
import type { Document } from "@contentful/rich-text-types";
import { InfoCircledIcon } from "@radix-ui/react-icons";

import { P } from "~/components/ui/text";
import {
  ABSOLUTE_POSITION,
  ITEMS_POSITION,
  JUSTIFY_POSITION,
} from "~/lib/constants";
import { cn } from "~/lib/ui";

import { isTypeContentTileTitle, isTypeRichText } from "../compiled";
import type { ImageWithHyperlinkContent } from "../types";
import { getMaxWidthClass, textAlignmentClasses } from "../util";
import { ContentfulPicture } from "./picture";
import { RichTextRender } from "./rich-text";
import TooltipWithRichText from "./tooltip-with-rich-text";

export const ContentfulImageWithHyperlink = ({
  content,
  className = "",
  textClassname = "",
  isBanner = false,
  numberOfColumns = [1, 1, 1],
}: {
  content: ImageWithHyperlinkContent;
  className?: string;
  textClassname?: string;
  isBanner?: boolean;
  numberOfColumns?: [number, number, number];
}) => {
  const inspectorProps = useContentfulInspectorMode();
  const fields = content.fields;
  const link = fields.link;
  // Get the Tailwind class for maxWidth

  const maxWidthClass = getMaxWidthClass(fields.maxWidth);

  if (!fields.image) return null;

  const imageElement = (
    <div
      className={`image-with-hyperlink relative mx-auto flex w-full flex-col ${maxWidthClass}`}
    >
      <div
        className={cn(
          "flex w-full rounded-lg",
          `${JUSTIFY_POSITION[fields.contentPosition]}`,
        )}
        {...inspectorProps({ entryId: content.sys.id, fieldId: "name" })}
      >
        <ContentfulPicture
          image={fields.image}
          className={className}
          isBanner={isBanner}
          numberOfColumns={numberOfColumns}
        />
      </div>
      <div
        className={cn(
          "mt-[15px]",
          fields.isContentOverlay &&
            `absolute ${
              ABSOLUTE_POSITION[fields.contentPosition]
            } m-auto mt-0 w-fit`,
        )}
      >
        <div
          className={cn(
            `flex h-full flex-col ${
              JUSTIFY_POSITION[fields.contentPosition]
            }  text-left ${ITEMS_POSITION[fields.contentPosition]}`,
            fields.isContentOverlay &&
              `items-center justify-center text-center`,
          )}
        >
          {fields.richText && (
            <div className={"w-full max-w-xl"}>
              {fields.richText.map(richContent =>
                isTypeContentTileTitle(richContent) ? (
                  <div
                    key={richContent.sys.id}
                    className="flex flex-col items-start gap-1"
                  >
                    {"title" in richContent.fields && (
                      <P
                        className={cn(
                          "w-full font-roboto text-xl font-medium leading-8 md:text-xl lg:text-2xl",
                          textAlignmentClasses[
                            richContent.fields.textAlignment ?? "left"
                          ],
                        )}
                      >
                        {richContent.fields.title as string}
                      </P>
                    )}
                    {"description" in richContent.fields && (
                      <P
                        className={cn(
                          "w-full text-base",
                          textAlignmentClasses[
                            richContent.fields.textAlignment ?? "left"
                          ],
                        )}
                      >
                        {richContent.fields.description as string}
                      </P>
                    )}
                  </div>
                ) : isTypeRichText(richContent) ? (
                  <RichTextRender
                    key={richContent.sys.id}
                    content={richContent.fields.richText as Document}
                    entryId={richContent.sys.id}
                    color={richContent.fields.textColour as string | undefined}
                    textAlignment={
                      richContent.fields.textAlignment as
                        | CanvasTextAlign
                        | undefined
                    }
                    textClassname={textClassname}
                  />
                ) : null,
              )}
            </div>
          )}

          {!isBanner && fields.toolTipLabel && fields.tooltip && (
            <div
              className={`${
                fields.richText && "mt-3"
              } inline-flex items-center gap-1 text-neutral-3`}
            >
              <TooltipWithRichText
                isLink={!!fields.link}
                content={
                  <RichTextRender
                    content={fields.tooltip}
                    entryId={fields.tooltip.data.id}
                  />
                }
              >
                {/* The Icon is set as children, so we can use everything we want as a trigger for the Tooltip */}
                <InfoCircledIcon
                  height={16.67}
                  width={16.67}
                  className="cursor-pointer"
                  color="#848484"
                />
              </TooltipWithRichText>
              <p className="w-fit text-sm font-bold text-neutral-3">
                {fields.toolTipLabel}
              </p>
            </div>
          )}
        </div>
      </div>
      {isBanner && fields.toolTipLabel && fields.tooltip && (
        <div
          className={`absolute inset-x-0 bottom-4 inline-flex items-center justify-center gap-1 text-neutral-3 md:bottom-3`}
        >
          <TooltipWithRichText
            content={
              <RichTextRender
                content={fields.tooltip}
                entryId={fields.tooltip.data.id}
              />
            }
          >
            {/* The Icon is set as children, so we can use everything we want as a trigger for the Tooltip */}
            <InfoCircledIcon
              height={16.67}
              width={16.67}
              className="cursor-pointer"
              color="#141414"
            />
          </TooltipWithRichText>
          <p className="w-fit text-xs font-bold text-brand-primary-black">
            {fields.toolTipLabel}
          </p>
        </div>
      )}
    </div>
  );

  return link ? (
    <a href={link} className="hover:cursor-pointer">
      {imageElement}
    </a>
  ) : (
    imageElement
  );
};
