import type { IconListContent } from "../types";
import { Icon } from "./icon";

type Props = {
  content: IconListContent;
  containerStyles?: string;
  iconHeight?: number;
};

export const IconList = ({
  content,
  containerStyles,
  iconHeight = 24,
}: Props) => {
  const title = content.fields.title;
  return (
    <>
      {title && <p className="mb-4 font-bold text-white">{title}</p>}
      <ul
        className={containerStyles ? containerStyles : "flex flex-wrap gap-5"}
      >
        {content.fields.icons &&
          content.fields.icons.map(field => (
            <li key={field.sys.id}>
              <Icon content={field} height={iconHeight} />
            </li>
          ))}
      </ul>
    </>
  );
};
