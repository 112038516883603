import { type ReactNode, useEffect, useState } from "react";

import * as Dialog from "@radix-ui/react-dialog";
import * as Tooltip from "@radix-ui/react-tooltip";

import { useMediaScreen } from "~/lib/utils/screens";

type TooltipProps = {
  children: ReactNode;
  content: ReactNode;
  isLink?: boolean;
};

const TooltipWithRichText = ({ children, content, isLink }: TooltipProps) => {
  const { isMobile, isTablet } = useMediaScreen();
  const [hydrated, setHydrated] = useState(false);

  useEffect(() => {
    setHydrated(true);
  }, []);

  if (!hydrated) {
    // Render a placeholder to avoid mismatch between server and client renders leading to hydration errors
    return <>{children}</>;
  }

  if (isLink && (isMobile || isTablet)) {
    // If the container is Link, and its a small screen the tooltip wont show.
    return <>{children}</>;
  }

  // Show Dialog/Modal for small screen devices
  if (isMobile || isTablet) {
    return (
      <Dialog.Root>
        <Dialog.Trigger asChild>
          <button className="p-0 focus-visible:outline-none">{children}</button>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 z-50 animate-fadeIn bg-dialog-overlay" />
          <Dialog.Content className="fixed left-1/2 top-1/2 z-50 flex max-h-[85vh] w-[90vw] max-w-[calc(100vw-64px)] -translate-x-1/2 -translate-y-1/2 transform animate-fadeIn flex-col items-center gap-4 rounded-lg bg-white p-3">
            {content}
            <Dialog.Close asChild>
              <button className="w-full rounded bg-brand-primary-black px-6 py-3 font-semibold text-white">
                Close
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  }

  return (
    <Tooltip.Provider delayDuration={200}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            side="top"
            align="center"
            className="z-50 mx-5 w-[458px] animate-fadeIn overflow-hidden rounded border border-neutral-6 bg-white p-5 shadow-tooltip zoom-in-95 data-[state=closed]:animate-fadeOut data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
          >
            {content}
            <div className="h-[8px] animate-fadeIn overflow-y-hidden zoom-in-95 data-[state=closed]:animate-fadeOut data-[state=closed]:zoom-out-95">
              <Tooltip.Arrow
                width={18}
                height={10}
                className="fill-white"
                style={{
                  stroke: "white",
                  filter: "drop-shadow(0 0px 4px gray)",
                  clipPath: "inset(1px -10px -10px -10px)",
                  marginTop: -2,
                  marginBottom: 4,
                }}
              />
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default TooltipWithRichText;
